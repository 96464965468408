<footer>
  <div class="content">
    <div class="logo-container">
      <img class="image" src="assets/logo.svg" routerLink="/" alt="Logo Germinar" />
    </div>
    <div class="links">
      <a class="link" routerLink="/terminos-y-condiciones">Términos y condiciones</a>
      <a class="link" routerLink="/politicas-de-privacidad">Políticas de privacidad</a>
    </div>
    <div class="social-networks-container">
      <a class="link" href="https://www.instagram.com/germinar_cl" target="_blank">
        <i class="pi pi-instagram"></i>
      </a>
    </div>
  </div>
</footer>